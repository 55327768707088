/* .images-container {
    width: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 30px;
} */

.list{
    display: flex;
    align-items: center;
    transition: all ease 0.3s;
    position: relative;

}

.image-item {
    position: relative;
    width: 230px;
    min-width: 230px;
    height: 200px;
    min-height: 200px;
    margin-right: 6px;
    margin-left: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    z-index: 98;    
}

.delete-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(46, 46, 46, 0.37);
    /* opacity: 0.7; */
}

.hidden{
    display: none;
    opacity: 0;
}

.side{
    width: 25px;
    height: 60px;
    background-color: rgba(46, 46, 46, 0.425);
    position: absolute;
    bottom: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.left-side{
    left: -5px;
}

.right-side{
    right: 5px;
}

.move{
    width: 40px;
    height: 90%;
    background-color: rgba(46, 46, 46, 0.548);
    position: absolute;
    z-index: 999;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.move-back{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.move-next{
    right: 0px;
}

.icon-arrow{
    color: #fff;
    font-size: 33px;
}

.upload__image-wrapper{
    width: 100%;
    height: 100%;
}

.imgs-col{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-left: 55px;

    scrollbar-width: thin;
    scrollbar-color: #ccc;
}

.imgs-col::-webkit-scrollbar{
    width: 6px;
    height: 15px;
}

.imgs-col::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 5px;
    height: 15px;
}


.drop-images{
    width: 100%;
    max-width: 200px;
    height: 90%;
    min-width: 190px;
    margin-right: 20px;
    margin-right: 15px;
    padding: 5px;
    position: relative;

}

.drop-image{
    cursor: normal;
    margin-bottom: 3px;
    width: 100%;
    max-width: 200px;
    height: 90%;
    object-fit: cover;
    border-radius: 5px;
    /* border: 2px solid rgba(204, 204, 204, 0.39);*/
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
     
}

.image-item__btn-wrapper{
    position: relative;
}

.trash-btn{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    position: absolute;
    top: -8px;
    right: -5px;
    cursor: pointer;
}

.disabled{
    cursor: not-allowed !important;
}

.mytooltip{
    max-width: 100%;
}