
// Start Styles FeedCard 
.item-content {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    >div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .item-header{
            width: 95%;
        }
    }
}
.item-card{
    width: 100%;
    padding: 25px;
    border-radius: 5px;
    height: auto;

    cursor: auto;
}
.item-title{
    width: auto;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    max-width: 50em;
    font-size: 19px;
    font-weight: 700;
    text-align: justify;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.item-content-p{
    width: auto;
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-align: justify;
    color: rgb(0, 0, 0);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.item-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.item-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-autor{
    width: auto;
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #9F9F9F;
}

.item-acao{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-button{
    outline: none;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    margin-left: 15px;

    >img{
        width: 24px;
        height: 24px;
        display: block;
    }
}

.thumbnail{
    width: 150px;
    height: 150px;
    border-radius: 5px;
    display: block;    
}

@media screen and (max-width: 600px) {
    .thumbnail{
        display: none;
    }

    .item-content {
        >div{
            width: 100%; 
            .item-header{
                width: 100%;
            }
        }
    }
}

// ********* End Styles FeedCard ************

.sd-bar{
    max-height: 295px;
    overflow-x: hidden;

    &::-webkit-scrollbar{
        width: 5px;

    }
    &::-webkit-scrollbar-thumb {
        background-color: #9F9F9F;    /* color of the scroll thumb */
        border-radius: 5px;       /* roundness of the scroll thumb */
    }
}

.content-menu{
    background-color: #FFFF;
    border-radius: 5px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    >button{
        &:focus {
            outline: thin dotted;
                outline: 0px auto -webkit-focus-ring-color;
                outline-offset: 0px;
            }
    }
}


.button-area{
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 0;
    text-align: start;
    outline: none;
    padding: 20px;

    &:focus {
        outline: thin dotted;
            outline: 0px auto -webkit-focus-ring-color;
            outline-offset: 0px;
        }
}

.button-subarea{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;
    background-color: #000;
    height: 50px;
    border: 0;
    outline: none;
    padding: 20px;
    &:focus {
        outline: thin dotted;
            outline: 0px auto -webkit-focus-ring-color;
            outline-offset: 0px;
        }

    .sub-itens {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .p {
            text-align: start;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }
}
.button-results{
    width: 100%;
    height: 50px;
    border: 0;
    outline: none;
    padding: 20px;
    background: #FFF;
    &:focus {
        outline: thin dotted;
            outline: 0px auto -webkit-focus-ring-color;
            outline-offset: 0px;
        }
}

.text-results{
    width: 100%;
    font-size: 14px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.length-results{
    max-width: 28px;
    height: 28px;
    padding: 5px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #31AF89;
    color: #FFF;
    font-size: 14px;
}


// NAVPAGES

.filter{
    background-color: #31AF89;
    border-color: #31AF89;
    color: #FFF;

    height: 35px;
}

.filter-input{
    display: flex;
    align-items: center;
    width: 350px;
    height: 35px;
}

.m-none{
    margin: 0;
}

.col{
    justify-content: start;
    margin: 0;
}

.content-table{
    padding: 3px;
}

.view{
    outline: none !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent;
    
    >img {
        width: 22px;

        &:hover{
            filter: 
            invert(48%) 
            sepia(13%) 
            saturate(3207%) 
            hue-rotate(130deg) 
            brightness(95%) 
            contrast(80%);
        }
    }
}

.header-table{
    background-color:#EDF2F9 ;
    
    >th{
        font-size: 14px;
        color: #3C3C3C;
    }
}

.init{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 3px;
    background-color: #31AF89;
    font-size: 18px;
    border-radius: 50%;

    font-size: bold;
    color: #FFF;
    margin-right: 13px;
}

.content-col{
    display: flex;
    align-items: center;

    >p{
        margin: 0;
    }
}

tbody {
    > tr{
        >td{
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.pag-table{
    max-width: 100% !important;
    padding: 30px 0 !important;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}


.content-btn-area{
    display: flex;
    justify-content: center;
    align-items: center;


}

.btn-table{

    outline: none !important;
    -webkit-box-shadow: none !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #D8E2EF;
    border-radius: 5px;
    opacity: 1;
    margin: 0 10px;
    width: 35px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
}

.btn-table-b{

    outline: none !important;
    -webkit-box-shadow: none !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000029;
    border: 1px solid #D8E2EF;
    border-radius: 5px;
    opacity: 1;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.app{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #31AF89;
    padding: 3px;
    border-radius: 5px;

    >p{
        
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 14px;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 1; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
}

.rep{
    width: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #FF6766;
    padding: 3px;
    border-radius: 5px;

    >p{
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 14px;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 1; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
}

.lood{
    width: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #FFB048;
    padding: 3px;
    border-radius: 5px;

    >p{
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 14px;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 1; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
}

.edit{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #FFBD66;
    padding: 3px;
    border-radius: 5px;

    >p{
        margin: 0;
        padding: 0;
        color: #FFF;
        font-size: 14px;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 1; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
}

.select{
    margin: 3px;
    &:focus{
        color: #31AF89;
    }
}


.like{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    >p{
        width: auto;
    }
}

.desc-img{
    font-size: 17px !important;

    >ol {
        padding: 20px;
    }
    ul {
        padding: 20px;
    }
    >p{
        width: 100%;
    >img{
        max-width: 100%;
    }
}
}