.icon_card {
    max-width: 70px;
    max-height: 70px;
    object-fit: cover;
}

.card_dash{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    text-decoration: none;
    transition: 0.5s;    
}

.card_dash:hover{
    cursor: pointer;
    background-color: rgb(245, 245, 245);
}

.label_icon{
    width: 100%;
    font-size: 16px;
    color: #484848;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
}

.content-img{
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.card_view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   

}

.lg-v{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.flex-itens{
    flex: 4;
    display: flex;
    flex-direction: row;
    
}
.w-nav-v{
    max-height: 85%;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 0 3px #00000029;
    background-color: #FFFF;
}
.navMarginBotton{
    margin-top: 20px;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
