.draft-alert {
  opacity: 1;
  animation: fade 2s linear;
  transition: all ease 1s;
}


@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.draft-alert-out{
  opacity: 1;
  animation: fadeOut 2s linear;
  transition: all ease 1s;
}

@keyframes fadeOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}


