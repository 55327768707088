
.review-card {
    padding: 20px;
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #FFF;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}

.review-title{
    padding: 15px 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 10px;

    >h5{
        font-size: 19px;
        font-weight: 600;
        margin: 0;
    }

    >strong {

        height: 30px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: normal;

        color: #FFF;
        background-color: #31AF89;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 10px;
    }
}

.review-status{
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    >p{
        margin: 0;

    }
    >strong {
        height: 30px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: normal;

        color: #FFF;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 10px; 
        margin-left: 10px; 
    }
}

.approved{
    background-color: #31AF89;
}

.disapproved{
    background-color: #FF6766;
}

.waiting{
    background-color: #FFBD66;
}

.review-link{

    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 10px;

    > a{
        margin: 0;
        display: flex;
        align-items: center;
        color: #31AF89;
        font-weight: 600;

        text-decoration: none;
        overflow: none;
        outline: none;

        &:hover{
            color: #236b55;
        }
    }
}