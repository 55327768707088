.row__itens{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

td{
    margin: 0 !important;
}

.item{
    display: flex;
    align-items: center;
    margin: 0;
    min-height: 30px !important;

}