.scroll::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
.scroll::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}
.scroll::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

.num__line{
    overflow: hidden; // Removendo barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final
    display: -webkit-box;
    -webkit-line-clamp: 3; // Quantidade de linhas
    -webkit-box-orient: vertical; 
}

.item__notification{
    margin-bottom: 8px;
    width: 95% !important;
    padding: 5px !important;
    border-radius: 5px !important;
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 5px;
    opacity: 1;
}

.center__notification{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.mark__notificationTrue{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 15px;
    height: 15px;

    border-radius: 20px;
    background-color: #31AF89;

    animation-name: stretch;
    animation-duration: 2.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    
    @keyframes stretch {
            0% {
                transform: scale(0.8);
                background-color: #CCC;
                border-radius: 100%;
            }
  
            50% {
                background-color: #31AF89;
            }
  
            100% {
                transform: scale(1.2);
                background-color: #31AF89;
            }
        }
}
.mark__notification{
    width: 5px;
    height: 5px;

    border-radius: 20px;
    background-color: #FFFF;
}